import {FiCommand} from 'react-icons/fi';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const customerListSelection = () =>
	defineField({
		name: 'customerListSelection',
		title: 'Kundecaseliste, utvalg',
		type: 'object',
		icon: FiCommand,
		fields: [customers(), layout(), backgroundColor()],
		preview: {
			select: {
				title: 'title',
				customers: 'customers',
			},
			prepare: (select) => {
				const title = select.title ?? `Liste med ${select.customers?.length ?? 0} kunder`;

				return {
					title: title,
					subtitle: 'Kundecaseliste, utvalg',
				};
			},
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const customers = () =>
	defineField({
		name: 'customers',
		title: 'Kundecaser',
		description: 'Kundene som skal vises i kundecaselisten.',
		type: 'array',
		group: 'content',
		of: [{type: 'reference', to: [{type: 'customer'}]}],
		validation: (Rule: Rule) => Rule.required(),
	});

export type CustomerListSelectionSchema = Output<typeof customerListSelection>;
