import {RiImageFill} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {
	CALL_TO_ACTION,
	EMPHASIS,
	LINK,
	portableText,
	STRONG,
	UNDERLINE,
} from '@/sanity/components/portable-text/schema';
import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {layout} from '@/sanity/schemas/primitives/layout';

export const textWithImageSplit = () =>
	defineField({
		name: 'textWithImageSplit',
		title: 'Tekst med sidestilt bilde',
		icon: RiImageFill,
		type: 'object',
		fields: [title(), image(), content(), backgroundColor(), layout(), alignment()],
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
		preview: {
			select: {
				title: 'title',
				image: 'image',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Tekst med sidestilt bilde',
				media: select?.image?.asset ? select.image : RiImageFill,
			}),
		},
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		name: 'image',
		title: 'Bilde',
		group: 'content',
		validation: (Rule: Rule) => Rule.required(),
	});

const content = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			styles: [],
			lists: [],
			decorators: [STRONG, EMPHASIS, UNDERLINE],
			customTypes: [CALL_TO_ACTION],
		}),
		name: 'content',
		title: 'Innhold',
		group: 'content',
	});

const alignment = () =>
	defineField({
		name: 'alignment',
		title: 'Bildejustering',
		type: 'string',
		options: {
			list: [
				{title: 'Venstre', value: 'left'},
				{title: 'Høyre', value: 'right'},
			],
			layout: 'radio',
		},
		initialValue: 'left',
		group: 'layout',
	});

export type TextWithImageSplitSchema = Output<typeof textWithImageSplit> & {
	_type: 'textWithImageSplit';
};
