import {RiQuestionAnswerLine} from 'react-icons/ri';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const contactFormSection = () =>
	defineField({
		name: 'contactFormSection',
		type: 'object',
		title: 'Kontaktskjema',
		fields: [layout(), backgroundColor()],
		icon: RiQuestionAnswerLine,
		preview: {
			prepare: () => ({
				title: 'Kontaktskjema',
				media: RiQuestionAnswerLine,
			}),
		},
		groups: [
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

export type SanityContactFormSectionSchema = Output<typeof contactFormSection> & {
	_type: 'contactFormSection';
};
