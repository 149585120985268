import {RiImageAddLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {ALT_TEXT, CAPTION, CREDIT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {layout} from '@/sanity/schemas/primitives/layout';

export const gallery = () =>
	defineField({
		name: 'gallery',
		title: 'Bildegalleri',
		type: 'object',
		icon: RiImageAddLine,
		fields: [images(), layout(), aspectRatio(), backgroundColor()],
		preview: {
			select: {
				images: 'images',
			},
			prepare: ({images}) => {
				const count = images?.length ?? 0;
				const image = images?.[0]?.asset ? images[0] : RiImageAddLine;
				return {
					title: `Bildegalleri med ${count} bilde${count === 1 ? '' : 'r'}`,
					subtitle: 'Bildegalleri',
					media: image,
				};
			},
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const images = () =>
	defineField({
		of: [{...imageWeb({fields: [ALT_TEXT, CAPTION, CREDIT]})}],
		name: 'images',
		title: 'Bilder',
		description:
			'Bilder som skal vises i galleriet. Er det kun ett bilde, vil det vises som et enkelt bilde.',
		type: 'array',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

const aspectRatio = () =>
	defineField({
		name: 'aspectRatio',
		title: 'Bildeformat',
		type: 'string',
		initialValue: '3:2',
		options: {
			list: [
				{title: '3:2', value: '3:2'},
				{title: '16:9', value: '16:9'},
				{title: '1:1', value: '1:1'},
			],
			layout: 'radio',
		},
		description: 'Bildets bredde i forhold til høyde',
		group: 'layout',
	});

export type GallerySchema = Output<typeof gallery> & {_type: 'gallery'};
