import {defineField} from 'sanity-typed-schema';

import {customerListAll} from '@/custom/customer/sanity/customer-list-all-container/schema';
import {customerListSelection} from '@/custom/customer/sanity/customer-list-selection-container/schema';
import {featuredCardList} from '@/custom/featured-card-list/sanity/schema';
import {articleListAll} from '@/extensions/article/sanity/article-list-all-container/schema';
import {articleListSelection} from '@/extensions/article/sanity/article-list-selection-container/schema';
import {contactFormSection} from '@/extensions/contact-form/sanity/contact-form-section-container/contact-form-section';
import {personListAll} from '@/extensions/person/sanity/person-list-all-container/schema';
import {personListGroup} from '@/extensions/person/sanity/person-list-group-container/schema';
import {personListSelection} from '@/extensions/person/sanity/person-list-selection-container/schema';

import {gallery} from './gallery-container/schema';
import {linkListSelection} from './link-list-selection-container/schema';
import {text} from './text-container/schema';
import {textWithImageSplit} from './text-with-image-split-container/schema';

export const contentBlocks = () =>
	defineField({
		name: 'content',
		title: 'Innhold',
		description:
			'En liste med blokker som utgjør innholdet på denne siden. Blokkene kan inneholde tekst, bilder og andre elementer og kan fritt kombineres.',
		type: 'array',
		of: [
			// Klart foundation
			text(),
			textWithImageSplit(),
			gallery(),
			linkListSelection(),

			// Article extension
			articleListSelection(),
			articleListAll(),

			// Person extension
			personListSelection(),
			personListAll(),
			personListGroup(),

			// Contact form extension
			contactFormSection(),

			// Customer
			customerListSelection(),
			customerListAll(),

			// Featured card list
			featuredCardList(),
		],
	});
