import {RiTeamLine} from 'react-icons/ri';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const personListAll = () =>
	defineField({
		name: 'personListAll',
		title: 'Personliste, alle',
		icon: RiTeamLine,
		type: 'object',
		fields: [title(), tagline(), orderBy(), layout(), backgroundColor()],
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
		preview: {
			select: {
				orderBy: 'orderBy',
			},
			prepare: ({orderBy}) => {
				let title = 'Alle personer';

				switch (orderBy) {
					case 'nameAtoZ':
						title += ' etter navn, A-Å';
						break;
					case 'nameZtoA':
						title += ' etter navn, Å-A';
						break;
					default:
						title += ' etter navn, A-Å';
						break;
				}

				return {
					title: title,
					subtitle: 'Personliste, alle',
				};
			},
		},
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Undertittel',
		type: 'string',
		group: 'content',
	});

const orderBy = () =>
	defineField({
		name: 'orderBy',
		title: 'Visningsrekkefølge',
		description: 'Rekkefølgen listen med personer skal vises i.',
		type: 'string',
		initialValue: 'nameAtoZ',
		options: {
			list: [
				{title: 'Etter navn, A-Å', value: 'nameAtoZ'},
				{title: 'Etter navn, Å-A', value: 'nameZtoA'},
			],
		},
		group: 'content',
	});

export type PersonListAllSchema = Output<typeof personListAll> & {
	_type: 'personListAll';
};
