import {FiGrid} from 'react-icons/fi';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {
	EMPHASIS,
	portableText,
	STRIKE_THROUGH,
	STRONG,
} from '@/sanity/components/portable-text/schema';
import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {imageWeb} from '@/sanity/schemas/primitives/image-web';
import {layout} from '@/sanity/schemas/primitives/layout';
import {link} from '@/sanity/schemas/primitives/link';

export const featuredCardList = () =>
	defineField({
		name: 'featuredCardList',
		title: 'Fremhevet kortliste',
		type: 'object',
		icon: FiGrid,
		fields: [heading(), cards(), layout(), backgroundColor()],
		preview: {
			select: {
				title: 'heading',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Fremhevet kortliste',
			}),
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const cards = () =>
	defineField({
		name: 'cards',
		title: 'Kort',
		type: 'array',
		group: 'content',
		validation: (Rule: Rule) => Rule.required(),
		of: [card()],
	});

const card = () =>
	defineField({
		name: 'card',
		title: 'Kort',
		type: 'object',
		group: 'content',
		validation: (Rule: Rule) => Rule.required(),
		fields: [title(), tagline(), icon(), description(), ...link().fields],
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Tagline',
		type: 'string',
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Tittel',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
	});

const description = () =>
	defineField({
		...portableText({
			annotations: [],
			decorators: [STRONG, EMPHASIS, STRIKE_THROUGH],
			customTypes: [],
			styles: [],
			lists: [],
		}),
		name: 'description',
		title: 'Beskrivelse',
	});
export type CardDescriptionSchema = Output<typeof description>;

const icon = () =>
	defineField({
		...imageWeb({fields: []}),
		name: 'icon',
		title: 'Ikon',
		description: 'Brukes som et lite ikon til kortet. Anbefalt filtype er SVG eller PNG.',
	});

export type FeaturedCardListSchema = Output<typeof featuredCardList> & {
	_type: 'featuredCardList';
};
