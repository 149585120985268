import {RiTeamLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const personListGroup = () =>
	defineField({
		name: 'personListGroup',
		title: 'Personliste etter gruppe, utvalg',
		type: 'object',
		icon: RiTeamLine,
		fields: [title(), tagline(), groups(), layout(), backgroundColor()],
		preview: {
			select: {
				title: 'title',
				groups: 'groups',
			},
			prepare: (select) => {
				const title = select.title ?? `Liste med ${select.groups?.length ?? 0} grupper`;

				return {
					title: title,
					subtitle: 'Personliste etter gruppe, utvalg',
				};
			},
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const title = () =>
	defineField({
		name: 'title',
		title: 'Overskrift',
		type: 'string',
		group: 'content',
	});

const tagline = () =>
	defineField({
		name: 'tagline',
		title: 'Undertittel',
		type: 'string',
		group: 'content',
	});

const groups = () =>
	defineField({
		name: 'groups',
		title: 'Grupper',
		description: 'En sortert liste med grupper som skal vises i innholdsblokken.',
		group: 'content',
		type: 'array',
		of: [{type: 'reference', to: [{type: 'personGroup'}]}],
		validation: (Rule: Rule) => Rule.required(),
	});

export type PersonListGroupSchema = Output<typeof personListGroup> & {
	_type: 'personListGroup';
};
