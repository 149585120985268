import {FiCommand} from 'react-icons/fi';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const customerListAll = () =>
	defineField({
		name: 'customerListAll',
		title: 'Kundecaseliste, alle',
		icon: FiCommand,
		type: 'object',
		fields: [orderBy(), layout(), backgroundColor()],
		preview: {
			select: {
				orderBy: 'orderBy',
			},
			prepare: ({orderBy}) => {
				let title = 'Alle kunder';

				switch (orderBy) {
					case 'publishDateNewestFirst':
						title += ' etter publiseringsdato, nyeste først';
						break;
					case 'publishDateOldestFirst':
						title += ' etter publiseringsdato, eldste først';
						break;
					case 'titleAtoZ':
						title += ' etter tittel, A-Å';
						break;
					case 'titleZtoA':
						title += ' etter tittel, Å-A';
						break;
					default:
						title += ' etter publiseringsdato, nyeste først';
						break;
				}

				return {
					title: title,
					subtitle: 'Kundecaseliste, alle',
				};
			},
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
	});

const orderBy = () =>
	defineField({
		name: 'orderBy',
		title: 'Visningsrekkefølge',
		description: 'Rekkefølgen listen med kunder skal vises i.',
		type: 'string',
		initialValue: 'publishDateNewestFirst',
		options: {
			list: [
				{
					title: 'Etter publiseringstidspunkt, nyeste først',
					value: 'publishDateNewestFirst',
				},
				{
					title: 'Etter publiseringstidspunkt, eldste først',
					value: 'publishDateOldestFirst',
				},
				{title: 'Etter tittel, A-Å', value: 'titleAtoZ'},
				{title: 'Etter tittel, Å-A', value: 'titleZtoA'},
			],
		},
		group: 'content',
	});

export type CustomerListAllSchema = Output<typeof customerListAll>;
