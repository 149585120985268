import {FiCommand} from 'react-icons/fi';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField, defineType} from 'sanity-typed-schema';

import {contentBlocks} from '@/sanity/components/content-blocks/schema';
import {EMPHASIS, portableText, STRONG} from '@/sanity/components/portable-text/schema';
import {ALT_TEXT, CAPTION, CREDIT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {meta} from '@/sanity/schemas/primitives/meta';
import {CUSTOMER_PAGE_PATH} from '@/shared/constants';

export const customer = () =>
	defineType({
		name: 'customer',
		type: 'document',
		title: 'Kundecase',
		icon: FiCommand,
		fields: [title(), slug(), heading(), introduction(), image(), contentBlocks(), meta()],
		preview: {
			select: {
				title: 'title',
				slug: 'slug',
				image: 'image',
			},
			prepare: (select) => ({
				title: select.title,
				subtitle: `${CUSTOMER_PAGE_PATH}/` + (select.slug?.current ?? '...'),
				media: select.image ?? FiCommand,
			}),
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		description:
			'Tittelen vises i nettleserens vidustittel, menyer og listevisninger og bør være kort og konsis. Merk at tittelen er forskjellig fra overskriften, som redigeres lenger ned.',
		validation: (Rule: Rule) => Rule.required(),
	});

const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'En kort introduduksjon til denne kunden.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
	});

const slug = () =>
	defineField({
		name: 'slug',
		type: 'slug',
		title: 'Slug',
		description:
			'Siste del av URLen til denne kunden på nettstedet. Slug bør formatteres med små bokstaver og uten spesialtegn. Det enkleste er å generere denne basert på kundecasens tittel.',
		options: {
			source: 'title',
		},
		validation: (Rule: Rule) =>
			Rule.custom((param?: {current: string}) => {
				if (!param) {
					return 'Obligatorisk';
				}
				if (param.current?.includes(' ') || param.current?.includes('/')) {
					return 'Slug kan ikke inneholde mellomrom eller skråstreker';
				}
				return true;
			}),
	});

const introduction = () =>
	defineField({
		...portableText({
			decorators: [STRONG, EMPHASIS],
			annotations: [],
			styles: [],
			lists: [],
			customTypes: [],
		}),
		name: 'introduction',
		title: 'Ingress',
		description:
			'En kort tekst som oppsummerer hovedinnholdet i kundecasen på et avsnitt eller to.',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT, CREDIT, CAPTION]}),
		description:
			'Et bilde som representerer innholdet i kundecasen. Brukes gjerne sammen med ingressen i listevisninger.',
	});

export type CustomerSchema = Output<typeof customer>;
