import {RiText} from 'react-icons/ri';
import {toPlainText} from '@portabletext/react';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {
	BULLET,
	CALL_TO_ACTION,
	EMPHASIS,
	H2,
	H3,
	H4,
	H5,
	H6,
	IMAGE,
	LINK,
	NUMBERED,
	portableText,
	QUOTE,
	STRIKE_THROUGH,
	STRONG,
	UNDERLINE,
	VIDEO,
} from '@/sanity/components/portable-text/schema';
import {backgroundColor} from '@/sanity/schemas/primitives/background-color';
import {layout} from '@/sanity/schemas/primitives/layout';

export const text = () =>
	defineField({
		name: 'textSection',
		title: 'Tekst',
		icon: RiText,
		type: 'object',
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
		fields: [content(), layout(), backgroundColor()],
		preview: {
			select: {
				content: 'content',
			},
			prepare: (select) => {
				const content = select.content?.length > 0 ? select.content : null;
				return {
					title: content ? toPlainText(content) : 'Mangler tekst...',
					subtitle: 'Tekst',
					media: RiText,
				};
			},
		},
	});

const content = () =>
	defineField({
		...portableText({
			styles: [H2, H3, H4, H5, H6, QUOTE],
			annotations: [LINK],
			lists: [BULLET, NUMBERED],
			decorators: [STRONG, EMPHASIS, UNDERLINE, STRIKE_THROUGH],
			customTypes: [CALL_TO_ACTION, IMAGE, VIDEO],
		}),
		name: 'content',
		group: 'content',
		title: 'Innhold',
		validation: (Rule: Rule) => Rule.required(),
	});

export type TextSchema = Output<typeof text> & {_type: 'textSection'};
