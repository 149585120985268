import {defineField} from 'sanity-typed-schema';

import {containerBackgroundColors} from '@/components/container';

export const backgroundColor = () =>
	defineField({
		name: 'backgroundColor',
		title: 'Bakgrunnsfarge',
		type: 'string',
		options: {
			list: [...containerBackgroundColors],
			layout: 'radio',
		},
		initialValue: 'white',
		group: 'style',
	});
